import React, { useState } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/template/webinar-post.scss"
import BackgroundImage from "gatsby-background-image"
import CallbackForm from "../components/CallbackForm/CallbackForm"
import GravityFormForm from "gatsby-plugin-gravity-forms"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { ApolloProvider } from "@apollo/client"
import { client } from "../hooks/apolloClient"
const WebinarPostTemplate = ({ data }) => {
  const [openCallback, setOpenCallback] = useState(false)
  const post = data?.post
  const form = { wpGfForm: data?.webinarForm, wp: data?.wp }
  const image = getImage(post?.featuredImage?.node?.localFile)
  const bgImage = convertToBgImage(image)

  return (
    <ApolloProvider client={client}>
      <Layout>
        <div className="post-headline">
          <BackgroundImage className="post-cover" Tag="div" {...bgImage}>
            <div className="container">
              <div
                className="callback-button"
                onClick={() => {
                  setOpenCallback(!openCallback)
                }}
              >
                <p>Request a callback today »</p>
              </div>
              <div className="title-wrapper">
                {post?.title && <h1>{parse(post?.title)}</h1>}
              </div>
            </div>
            <CallbackForm open={openCallback} setOpen={setOpenCallback} />
          </BackgroundImage>
        </div>
        <div className="container main-post-wrapper">
          <h6 className="date-time">
            {post?.webinar?.dateTime} |{" "}
            <a className="webinar-link" href={post?.webinar?.meetingLink}>
              Register now
            </a>
          </h6>
          {post?.content && (
            <section className="main-post-content" itemProp="articleBody">
              {parse(post?.content)}
            </section>
          )}
          <div className="webinar-registration">
            <h3 className="form-title">Webinar Registration</h3>
            <p className="form description">
              Register your interest for our webinars
            </p>
            <div className="form-wrapper">
              <GravityFormForm data={form} />
            </div>
          </div>
        </div>
      </Layout>
    </ApolloProvider>
  )
}
export const Head = ({ data }) => (
  <Seo
    title={data?.seo?.seo?.title}
    description={data?.seo?.seo?.metaDesc}
    image={data?.seo?.featuredImage?.node?.sourceUrl}
    props={data?.seo?.seo}
  />
)

export default WebinarPostTemplate

export const pageQuery = graphql`
  query WebinarPostById($id: String!) {
    post: wpWebinar(id: { eq: $id }) {
      content
      excerpt
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
      }
      title
      uri
      slug
      webinar {
        dateTime
        meetingLink
      }
    }
    seo: wpWebinar(id: { eq: $id }) {
      featuredImage {
        node {
          sourceUrl
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    webinarForm: wpGfForm(databaseId: { eq: 6 }) {
      ...GravityFormFields
    }
    wp {
      ...GravityFormSettings
    }
  }
`
